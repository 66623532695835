<template>
  <section
    :class="[
      'contact',
      'py-24',
      'text-center',
      'px-4',
      isDarkTheme ? 'dark' : '',
    ]"
  >
    <h2 class="text-6xl font-bold mb-8" data-aos="fade-in">Contact Me</h2>
    <p class="text-lg mb-12" data-aos="fade-in" data-aos-delay="200">
      Interested in working together or have a question? Feel free to reach out.
    </p>
    <div
      class="contact-details flex justify-center space-x-4 text-lg"
      data-aos="fade-up"
      data-aos-delay="400"
    >
      <p>
        <font-awesome-icon icon="envelope" class="mr-2 fa-lg" />
        <a
          href="mailto:weibeck@outlook.com"
          class="text-blue-500 hover:text-blue-700"
          >Send me an e-mail</a
        >
      </p>
      <p>
        <font-awesome-icon icon="download" class="mr-2 fa-lg" />
        <a
          href="https://github.com/Weibeck/resume/raw/main/Mathias_Weibeck_CV.pdf"
          target="_blank"
          class="text-blue-500 hover:text-blue-700"
          >Download my resume</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.contact {
  background-color: #ffffff;
}

.dark {
  background-color: #121212;
  color: #ffffff;
}

.dark label {
  color: #bbbbbb;
}

.dark input,
.dark textarea {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.dark input::placeholder,
.dark textarea::placeholder {
  color: #bbbbbb;
}

.dark button {
  background-color: #4a4a4a;
}

.dark button:hover {
  background-color: #666666;
}
</style>

<template>
  <div
    :class="[
      'portfolio-item',
      'mb-4',
      'rounded',
      'overflow-hidden',
      'shadow-lg',
      isDarkTheme ? 'dark-theme' : '',
      'md:px-12',
      'sm:px-2',
    ]"
    class="mt-8"
  >
    <img :src="item.image" alt="" class="w-full min-h-full mt-4 object-contain" />
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ item.title }}</div>
      <p class="text-gray-700 text-base">
        {{ item.description }}
      </p>
      <a
        v-if="item.githubLink"
        :href="item.githubLink"
        target="_blank"
        class="text-blue-500 hover:underline"
        >GitHub Link</a
      >
      <a
        v-if="item.liveLink"
        :href="item.liveLink"
        target="_blank"
        class="text-blue-500 hover:underline mb-4"
        >Live Website</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDarkTheme: {
      // Darktheme Prop
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.portfolio-item {
  max-width: 1000px;
  width: 100%;
}

@media (max-width: 640px) {
  .portfolio-item {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .portfolio-item {
    height: auto;
  }
}

@media (max-width: 1280px) {
  .portfolio-item {
    height: auto;
  }
}

.portfolio-item:hover {
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}
.dark-theme .portfolio-item {
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.dark-theme .portfolio-item:hover {
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.4);
}
</style>

<template>
  <section
    class="h-screen w-screen flex flex-col md:flex-row"
    :class="[isDarkTheme ? 'dark-bg' : 'bg-white']"
  >
    <div
      class="w-full md:w-1/2 h-72 md:h-full bg-gray-100 flex items-center justify-center p-6 space-x-4 overflow-hidden relative"
    >
      <img
        src="@/assets/images/abstract-art.webp"
        alt="Abstract Art"
        class="absolute w-full h-full object-cover"
      />
    </div>
    <div
      class="w-full md:w-1/2 h-auto md:h-full flex flex-col items-start justify-center space-y-6 px-6"
      :class="[isDarkTheme ? 'text-white' : 'text-black']"
      data-aos="fade-in"
      data-aos-delay="300"
    >
      <h2 class="font-bold text-3xl md:text-5xl md:mb-4 self-start">
        About Me
      </h2>
      <div class="md:space-y-4 text-left self-start">
        <p class="text-2xl md:text-3xl">I am passionate about crafting</p>
        <p class="text-2xl md:text-3xl">unique online experiences and</p>
        <p class="text-2xl md:text-3xl">
          designing innovative digital solutions.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isDarkTheme: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.dark-bg {
  background-color: #121212;
}
</style>

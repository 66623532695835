import "./assets/tailwind.css";
import "devicon/devicon.min.css";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import LandingPage from "./views/LandingPage.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faShareAlt,
  faTimes,
  faEnvelope,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";

const routes = [{ path: "/", component: LandingPage }];

library.add(
  faShareAlt,
  faTimes,
  faTwitter,
  faGithub,
  faLinkedin,
  faEnvelope,
  faDownload
);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
AOS.init();

const router = createRouter({
  history: createWebHistory(),
  routes,
});

app.use(router).mount("#app");

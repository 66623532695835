<template>
  <div
    class="fixed right-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer lg:block hidden"
    @click="toggleOpen"
  >
    <div
      class="transition-all duration-500 ease-in-out overflow-hidden bg-white rounded-l-lg shadow-lg"
      :class="{
        'h-auto py-6 space-y-4 pl-4 w-16': isOpen,
        'h-16 px-4 flex items-center justify-center w-16': !isOpen,
      }"
    >
      <ul class="space-y-2" v-if="isOpen">
        <li v-for="(link, index) in links" :key="index">
          <a :href="link.url" target="_blank">
            <font-awesome-icon
              :icon="['fab', link.icon]"
              class="fa-2x"
            ></font-awesome-icon>
          </a>
        </li>
        <li>
          <font-awesome-icon
            icon="times"
            class="fa-2x text-gray-800"
          ></font-awesome-icon>
        </li>
      </ul>
      <font-awesome-icon
        v-else
        icon="share-alt"
        class="fa-2x text-gray-800"
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      links: [
        { url: "https://github.com/Weibeck", icon: "github" },
        { url: "https://twitter.com/mathiasweibeck", icon: "twitter" },
        {
          url: "https://www.linkedin.com/in/mathias-weibeck-60855618b/",
          icon: "linkedin",
        },
      ],
    };
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
a {
  color: black;
}
</style>

<template>
  <section
    :class="isDarkTheme ? 'dark-gray text-white' : 'bg-white text-black'"
    class="skills min-h-full w-full flex items-center justify-center lg:my-96"
    data-aos="fade-in"
  >
    <div
      class="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center"
    >
      <div
        class="w-full text-center mb-8 pr-4 py-8 md:w-1/2 md:text-right md:mb-0 md:pr-12 md:border-r border-gray-200"
      >
        <h2
          :class="isDarkTheme ? 'text-white' : 'text-black'"
          class="text-4xl md:text-6xl font-semibold mb-4 leading-none tracking-widest"
          data-aos="fade-in"
        >
          Skills
        </h2>
      </div>

      <div
        class="w-full md:w-1/2 p-6 md:p-8 grid grid-cols-2 md:grid-cols-4 gap-0 justify-items-center"
      >
        <div
          v-for="(skill, index) in skills"
          :key="skill.id"
          class="skill-icon relative large-icon"
          @mouseover="setActiveSkill(index)"
          @mouseout="setActiveSkill(null)"
          :data-aos="'fade-up'"
          :data-aos-delay="(index + 1) * 100"
        >
          <i :class="skill.icon" v-show="activeSkill !== index"></i>
          <div
            v-show="activeSkill === index"
            class="absolute inset-0 flex items-center justify-center text-size"
          >
            {{ skill.name }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    skills: {
      type: Array,
      required: true,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSkill: null,
    };
  },
  methods: {
    setActiveSkill(index) {
      this.activeSkill = index;
    },
  },
};
</script>

<style scoped>
.skill-icon img {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.skill-icon img:hover {
  filter: grayscale(0);
}

@media (max-width: 640px) {
  .skill-icon {
    font-size: 1.5em;
  }
}

.large-icon {
  font-size: 4em;
}

.text-size {
  font-size: 0.3em;
  transform: translateX(-30%);
}

.fade {
  transition: opacity 0.5s;
}
.hidden {
  opacity: 0;
}

.skill-cell {
  padding: 10px;
}
</style>

<template>
  <footer class="footer p-6 text-center text-white">
    <p>&copy; 2023 Mathias Weibeck. All rights reserved.</p>
    <div class="flex justify-center mt-4 space-x-4">
      <a href="https://www.linkedin.com/in/mathias-weibeck-60855618b/" class="text-white hover:text-gray-900">
        <font-awesome-icon :icon="['fab', 'linkedin']" class="fa-2x" />
      </a>
      <a href="https://github.com/Weibeck" class="text-white hover:text-gray-900">
        <font-awesome-icon :icon="['fab', 'github']" class="fa-2x" />
      </a>
      <a href="https://twitter.com/mathiasweibeck" class="text-white hover:text-gray-900">
        <font-awesome-icon :icon="['fab', 'twitter']" class="fa-2x" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer {
  background-color: #1b1b1b;
}
</style>

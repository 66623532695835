<template>
  <div :class="{ 'dark-theme': isDarkTheme }">
    <section
      :class="[
        'hero',
        'relative',
        'flex',
        'items-center',
        'justify-center',
        'text-center',
        'min-h-screen',
        { 'dark-theme': isDarkTheme },
      ]"
    >
      <div
        class="container mx-auto z-10 relative px-4 py-8 flex flex-col items-center justify-center"
      >
        <h1
          :class="[
            'text-3xl',
            'sm:text-4xl',
            'md:text-5xl',
            'lg:text-6xl',
            'font-semibold',
            'mb-4',
            'leading-none',
            'tracking-tight',
            isDarkTheme ? 'text-white' : 'text-black',
          ]"
        >
          Mathias Weibeck
        </h1>

        <p
          :class="[
            'text-lg',
            'md:text-xl',
            'mb-10',
            'max-w-lg',
            'mx-auto',
            'typing-effect',
            isDarkTheme ? 'text-gray-300' : 'text-gray-700',
          ]"
        >
          I'm a <span class="typed-text"></span
          ><span class="cursor">&nbsp;</span>
        </p>

        <img
          :src="isDarkTheme ? lightbulbOff : lightbulbOn"
          alt="Toggle theme"
          @click="toggleTheme"
          class="cursor-pointer fixed bottom-2 right-2 w-20 h-20 hidden lg:block"
        />
      </div>
    </section>

    <section :key="reRenderKey">
      <about-section :is-dark-theme="isDarkTheme" />
    </section>

    <section class="portfolio py-16 min-h-full lg:my-96 md:px-12">
      <Carousel>
        <Slide v-for="item in portfolioItems" :key="item.id">
          <portfolio-item :item="item" :is-dark-theme="isDarkTheme" />
        </Slide>

        <template #addons="{ currentSlide, length, go }">
          <Navigation />

          <div class="flex justify-center space-x-2">
            <button
              v-for="index in Array.from({ length })"
              :key="index"
              :class="[
                'w-4 h-4 rounded-full',
                currentSlide === index
                  ? isDarkTheme
                    ? 'bg-white'
                    : 'bg-black'
                  : isDarkTheme
                  ? 'bg-gray-500'
                  : 'bg-gray-300',
              ]"
              @click="go(index + 1)"
            />
          </div>
        </template>
      </Carousel>
    </section>

    <section>
      <skill-set :skills="skills" :is-dark-theme="isDarkTheme"></skill-set>
    </section>

    <section>
      <ContactSection :is-dark-theme="isDarkTheme" />
    </section>
  </div>
</template>

<script>
import PortfolioItem from "@/components/PortfolioItem.vue";
import LightbulbOff from "@/assets/images/lightbulb-off.png";
import LightbulbOn from "@/assets/images/lightbulb-on.png";
import SkillSet from "@/components/SkillSet.vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import AboutSection from "@/components/AboutSection.vue";
import ContactSection from "@/components/ContactSection.vue";

export default {
  components: {
    "portfolio-item": PortfolioItem,
    "skill-set": SkillSet,
    Carousel,
    Slide,

    Navigation,
    AboutSection,
    ContactSection,
  },

  mounted() {
    this.animateText(
      [
        "UI/UX Designer",
        "Creative Thinker",
        "Innovative Designer",
        "Responsive Web Developer",
        "Team Collaborator",
        "Problem Solver",
        "Efficiency Driver",
      ],
      ".typed-text",
      ".cursor"
    );
  },

  methods: {
    toggleTheme() {
      console.log("toggleTheme called");
      this.isDarkTheme = !this.isDarkTheme;

      // increment reRenderKey to force a re-render
      this.reRenderKey++;

      console.log("isDarkTheme is now", this.isDarkTheme);
    },

    animateText(words, typedTextSelector, cursorSelector) {
      const typedTextSpan = document.querySelector(typedTextSelector);
      const cursorSpan = document.querySelector(cursorSelector);

      let arrayIndex = 0;
      let charIndex = 0;

      function type() {
        if (charIndex < words[arrayIndex].length) {
          typedTextSpan.textContent += words[arrayIndex].charAt(charIndex);
          charIndex++;
          setTimeout(type, 200);
        } else {
          cursorSpan.classList.add("typing");
          setTimeout(erase, 2000);
        }
      }

      function erase() {
        if (charIndex > 0) {
          typedTextSpan.textContent = words[arrayIndex].substring(
            0,
            charIndex - 1
          );
          charIndex--;
          setTimeout(erase, 100);
        } else {
          arrayIndex++;
          if (arrayIndex >= words.length) arrayIndex = 0;
          cursorSpan.classList.remove("typing");
          setTimeout(type, 200);
        }
      }

      document.addEventListener("DOMContentLoaded", function () {
        if (words.length) setTimeout(type, 200);
      });
    },
  },

  data() {
    return {
      reRenderKey: 0,
      portfolioItems: [
      {
          id: 1,
          title: "React.js - Sci fi / Tech Theme website",
          description:
            "Scope: A  futuristic, sci-fi/tech-inspired website with a comprehensive shop layout, blog section, and all the essential features in web platforms.",
          image: require("@/assets/images/react-app-tech.webp"),
          liveLink: "https://www.suzpect.com",
        },
        {
          id: 2,
          title: "React Native - Social Media Application",
          description:
            "Scope: A social media app with post feeds, user profiles, and post creation, built with React Native and Expo.",
          image: require("@/assets/images/react-native-app.webp"),
          githubLink:
            "https://github.com/Weibeck/social-media-react-app-suzpect",
        },
        {
          id: 3,
          title: "One-page Website - Next.js / TailwindCSS",
          description:
            "Scope: One-page site built with Next.js and Tailwind CSS, showcasing modern, responsive design and efficient performance.",
          image: require("@/assets/images/nexobridge-web.webp"),
          liveLink: "https://www.nexobridge.com",
        },
        {
          id: 4,
          title: "Diet Website - WordPress",
          description:
            "Scope: A WordPress site focusing on improving my SEO experience, copywriting, and ghostwriting.",
          image: require("@/assets/images/weightlosstoolkits.webp"),
          liveLink: "https://www.weightlosstoolkits.com",
        },
        {
          id: 5,
          title: "Portfolio Website - Vue3 / TailwindCSS",
          description:
            "Scope: My first portfolio, built with Vue 3 and Tailwind CSS showcasing personal projects and skills.",
          image: require("@/assets/images/project-pf.webp"),
          githubLink:
            "https://github.com/Weibeck/Portfolio",
        },
        {
          id: 6,
          title: "Math Game - Phaser.js",
          description:
            "Scope: Educational browser game leveraging Phaser library for gamified learning.",
          image: require("@/assets/images/phaser-game.webp"),
          liveLink: "https://weibeck.github.io/QuickMathPhaserJS/",
        },
      ],
      skills: [
        { id: 1, name: "Next.js", icon: "devicon-nextjs-original" },
        { id: 2, name: "React", icon: "devicon-react-original" },
        { id: 3, name: "Git", icon: "devicon-git-plain" },
        { id: 4, name: "HTML", icon: "devicon-html5-plain" },
        { id: 5, name: "CSS", icon: "devicon-css3-plain" },
        { id: 6, name: "TypeScript", icon: "devicon-typescript-plain" },
        { id: 7, name: "C#", icon: "devicon-csharp-plain" },
        { id: 8, name: "WordPress", icon: "devicon-wordpress-plain" },
        { id: 9, name: "Drupal", icon: "devicon-drupal-plain" },
        { id: 10, name: "Vue.js", icon: "devicon-vuejs-plain" },
        { id: 11, name: "Tailwind CSS", icon: "devicon-tailwindcss-plain" },
        { id: 12, name: "Bootstrap", icon: "devicon-bootstrap-plain" },
        { id: 13, name: ".NET Core", icon: "devicon-dotnetcore-plain" },
        { id: 14, name: "MySQL", icon: "devicon-mysql-plain" },
        { id: 15, name: "VS Code", icon: "devicon-vscode-plain" },
        { id: 16, name: "Python", icon: "devicon-python-plain" },
      ],
      isDarkTheme: false,
      lightbulbOff: LightbulbOff,
      lightbulbOn: LightbulbOn,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

.hero {
  background: linear-gradient(135deg, #f9f9f9 0%, #e8e8e8 100%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.portfolio {
  background-color: #ffffff;
  justify-content: center;
  align-items: flex-start;
}

.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typed-text {
  white-space: pre;
}

.cursor {
  display: inline-block;
  width: 0.1em;
  height: 1em;
  vertical-align: middle;
  background-color: #000;
  animation: blink 0.7s infinite;
}

.cursor.typing {
  animation: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 1px solid rgb(94, 94, 94);
  border-radius: 50%;
}

.carousel__prev {
  left: 10%;
}

.carousel__next {
  right: 10%;
}

@media (max-width: 1024px) {
  .carousel__prev {
    left: -7%;
  }

  .carousel__next {
    right: -7%;
  }
}

@media (max-width: 640px) {
  .carousel__prev,
  .carousel__next {
    display: none;
  }
}

.dark-theme {
  background-color: #121212;
  color: #e0e0e0;
}

.hero.dark-theme {
  background: linear-gradient(135deg, #222 0%, #121212 100%);
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

.dark-theme .portfolio {
  background-color: #121212;
}

.dark-theme .typed-text {
  white-space: pre;
  color: #e0e0e0;
}

.dark-theme .cursor {
  display: inline-block;
  width: 0.1em;
  height: 1em;
  vertical-align: middle;
  background-color: #e0e0e0;
  animation: blink 0.7s infinite;
}

.dark-theme .carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: #121212;
  color: #e0e0e0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-theme .carousel__prev,
.dark-theme .carousel__next {
  box-sizing: content-box;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
}
.dark-theme .carousel__icon {
  fill: #e0e0e0;
}

.dark-theme .carousel__slide {
  background-color: #121212;
}

.dark-theme h1,
.dark-theme p {
  color: #e0e0e0;
}
</style>
